import React, { useEffect, useState } from "react";

const ImageModal = ({ url, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!url) {
      return;
    }

    setIsModalOpen(true);
  }, [url]);

  if (!url) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <img
        src={url}
        alt="example"
        style={{ maxWidth: "90%", maxHeight: "90%" }}
      />
    </div>
  );
};

export default ImageModal;
