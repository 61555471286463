import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ImageModal from "./modal";

import mainImage from "app/assets/images/main.jpg";
import companyNamesImage from "app/assets/images/company-names.png";

import whatsupIcon from "app/assets/icons/whatsapp.png";
import telegramIcon from "app/assets/icons/telegram.png";

import { projects } from "app/data";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Order = () => {
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  const [imageUrl, setImageUrl] = useState();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [iconWidth, setIconWidth] = useState(48);
  const [iconHeight, setIconHeight] = useState(48);

  // Function to update the window width
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Use useEffect to add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this runs once when the component mounts

  useEffect(() => {
    const width = windowWidth / 35;

    setIconWidth(width < 20 ? 20 : width);
    setIconHeight(width < 20 ? 20 : width);
  }, [windowWidth]);

  return (
    <div
      style={{
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        minHeight: 400,
        fontFamily: "Roboto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 12px",
          marginBottom: "4%",
        }}
      >
        <div
          style={{
            width: "48%",
            lineHeight: 1,
            color: "#014886",
            fontSize: "max(2vw, 14px)",
            fontFamily: "Roboto",
          }}
        >
          Выполняем быстро, качественно и надежно.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "50%",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: "#014886",
              marginBottom: "min(8%, 4%)",
              fontSize: "max(2vw, 12px)",
            }}
          >
            Возникли вопросы? Звоните!
          </div>
          <div
            style={{
              color: "#DA0000",
              fontSize: "max(2.5vw, 18px)",
              fontWeight: "bold",
            }}
          >
            <a href="tel:+998959634858" style={{ color: "#DA0000" }}>
              +998 95 963 4858
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          // height: 732,
          maxHeight: 500,
          marginBottom: "10%",
          // backgroundSize: "cover",
          // backgroundImage: `url(${mainImage})`,
          position: "relative",
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            color: "white",
            fontSize: "max(1.5vw, 16px)",
            padding: "max(1.5vw, 12px)",
            background: "#615c5c87",
            width: "100%",
          }}
        >
          Если не смогли связаться со мной, оставьте сообщение в Telegram, и я с
          вами свяжусь.
        </div>
        <img src={mainImage} style={{ width: "100%" }} />
        <div
          style={{
            display: "flex",
            height: "20%",
            justifyContent: "space-between",
            backgroundColor: "rgba(33,33,33,0.7)",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            alignItems: "center",
            padding: "6%",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              padding: "3% 6%",
              borderRadius: 4,
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#F73C3D",
              fontSize: "max(1.5vw, 12px)",
              height: "100%",
              color: "white",
            }}
          >
            <img
              src={whatsupIcon}
              style={{ marginRight: 8, width: iconWidth, height: iconHeight }}
            />
            <a href="tel:+998959634858" style={{ color: "white" }}>
              +998 95 963 4858
            </a>
          </div>

          <div
            style={{
              alignItems: "center",
              padding: "3% 6%",
              borderRadius: 4,
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#F73C3D",
              fontSize: "max(1.5vw, 12px)",
              height: "80%",
              color: "white",
            }}
          >
            <img
              src={telegramIcon}
              style={{ marginRight: 8, width: iconWidth, height: iconHeight }}
            />
            <a href="tel:+998959634858" style={{ color: "white" }}>
              +998 95 963 4858
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: "4%",
          marginBottom: "8%",
        }}
      >
        <div
          style={{
            marginBottom: "6%",
            color: "#0079E2",
            fontSize: "max(3vw, 32px)",
            fontWeight: "medium",
            fontFamily: "Roboto",
          }}
        >
          Наши Услуги
        </div>

        <div
          style={{
            lineHeight: 1.3,
            fontFamily: "Inter",
            fontSize: "max(2.1vw, 24px)",
            fontWeight: "normal",
            overflow: "hidden",
          }}
        >
          {projects.map((project, index) => {
            const { photos } = project;

            return (
              <div style={{ marginBottom: photos.length > 0 ? 64 : 0 }}>
                <div style={{ marginBottom: 10, letterSpacing: -2 }}>
                  {index + 1}. {project.title}
                </div>
                <Carousel swipeable showDots responsive={responsive}>
                  {project.photos.map((photo) => {
                    return (
                      <div
                        style={{
                          borderRadius: 4,
                          width: "100%",
                          height: "100%",
                          marginRight: 12,
                        }}
                        onClick={() => setImageUrl(photo.url)}
                      >
                        <img
                          src={photo.url}
                          style={{
                            width: "90%",
                            height: "90%",
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={{
          paddingBottom: "4%",
          marginBottom: "8%",
        }}
      >
        <div
          style={{
            color: "#0079E2",
            fontWeight: "medium",
            fontFamily: "Roboto",
            lineHeight: 1,
            marginBottom: "6%",
            color: "#0079E2",
            fontSize: "max(3vw, 32px)",
          }}
        >
          Сотрудничаем с Фирмами
        </div>
        <div>
          <img src={companyNamesImage} style={{ width: "100%" }} />
        </div>
      </div>

      <ImageModal url={imageUrl} onClose={() => setImageUrl()} />
    </div>
  );
};

export default Order;
