import projectPhotoOne1 from "app/assets/projects/one/01.jpg";
import projectPhotoOne2 from "app/assets/projects/one/02.jpg";
import projectPhotoOne3 from "app/assets/projects/one/03.jpg";
import projectPhotoOne4 from "app/assets/projects/one/04.jpg";
import projectPhotoOne5 from "app/assets/projects/one/05.jpg";
import projectPhotoOne6 from "app/assets/projects/one/06.jpg";
import projectPhotoOne7 from "app/assets/projects/one/07.jpg";
import projectPhotoOne8 from "app/assets/projects/one/08.jpg";
import projectPhotoOne9 from "app/assets/projects/one/09.jpg";
import projectPhotoOne10 from "app/assets/projects/one/10.jpg";
import projectPhotoOne11 from "app/assets/projects/one/11.jpg";
import projectPhotoOne12 from "app/assets/projects/one/12.jpg";
import projectPhotoOne13 from "app/assets/projects/one/13.jpg";

import projectPhotoTwo1 from "app/assets/projects/two/01.jpg";
import projectPhotoTwo2 from "app/assets/projects/two/02.jpg";
import projectPhotoTwo3 from "app/assets/projects/two/03.jpg";
import projectPhotoTwo4 from "app/assets/projects/two/04.jpg";
import projectPhotoTwo5 from "app/assets/projects/two/05.jpg";
import projectPhotoTwo6 from "app/assets/projects/two/06.jpg";
import projectPhotoTwo7 from "app/assets/projects/two/07.jpg";
import projectPhotoTwo8 from "app/assets/projects/two/08.jpg";
import projectPhotoTwo9 from "app/assets/projects/two/09.jpg";
import projectPhotoTwo10 from "app/assets/projects/two/10.jpg";
import projectPhotoTwo11 from "app/assets/projects/two/11.jpg";
import projectPhotoTwo12 from "app/assets/projects/two/12.jpg";
import projectPhotoTwo13 from "app/assets/projects/two/13.jpg";
import projectPhotoTwo15 from "app/assets/projects/two/15.jpg";

import projectPhotoThree1 from "app/assets/projects/three/01.jpg";
import projectPhotoThree2 from "app/assets/projects/three/02.jpg";
import projectPhotoThree3 from "app/assets/projects/three/03.jpg";
import projectPhotoThree4 from "app/assets/projects/three/04.jpg";
import projectPhotoThree5 from "app/assets/projects/three/05.jpg";

import projectPhotoFour1 from "app/assets/projects/four/01.jpg";
import projectPhotoFour2 from "app/assets/projects/four/02.jpg";
import projectPhotoFour3 from "app/assets/projects/four/03.jpg";
import projectPhotoFour4 from "app/assets/projects/four/04.jpg";
import projectPhotoFour5 from "app/assets/projects/four/05.jpg";
import projectPhotoFour6 from "app/assets/projects/four/06.jpg";
import projectPhotoFour7 from "app/assets/projects/four/07.jpg";
import projectPhotoFour8 from "app/assets/projects/four/08.jpg";
import projectPhotoFour9 from "app/assets/projects/four/09.jpg";
import projectPhotoFour10 from "app/assets/projects/four/10.jpg";
import projectPhotoFour11 from "app/assets/projects/four/11.jpg";
import projectPhotoFour12 from "app/assets/projects/four/12.jpg";
import projectPhotoFour13 from "app/assets/projects/four/13.jpg";
import projectPhotoFour14 from "app/assets/projects/four/14.jpg";

import projectPhotoFive1 from "app/assets/projects/five/01.jpg";
import projectPhotoFive2 from "app/assets/projects/five/02.jpg";
import projectPhotoFive3 from "app/assets/projects/five/03.jpg";
import projectPhotoFive4 from "app/assets/projects/five/04.jpg";
import projectPhotoFive5 from "app/assets/projects/five/05.jpg";
import projectPhotoFive6 from "app/assets/projects/five/06.jpg";
import projectPhotoFive7 from "app/assets/projects/five/07.jpg";
import projectPhotoFive8 from "app/assets/projects/five/08.jpg";
import projectPhotoFive9 from "app/assets/projects/five/09.jpg";
import projectPhotoFive10 from "app/assets/projects/five/10.jpg";

import projectPhotoSix1 from "app/assets/projects/six/01.jpg";
import projectPhotoSix2 from "app/assets/projects/six/02.jpg";
import projectPhotoSix3 from "app/assets/projects/six/03.jpg";
import projectPhotoSix4 from "app/assets/projects/six/04.jpg";
import projectPhotoSix5 from "app/assets/projects/six/05.jpg";
import projectPhotoSix6 from "app/assets/projects/six/06.jpg";
import projectPhotoSix7 from "app/assets/projects/six/07.jpg";
import projectPhotoSix8 from "app/assets/projects/six/08.jpg";
import projectPhotoSix9 from "app/assets/projects/six/09.jpg";
import projectPhotoSix10 from "app/assets/projects/six/10.jpg";
import projectPhotoSix11 from "app/assets/projects/six/11.jpg";

import projectPhotoSeven1 from "app/assets/projects/seven/01.jpg";
import projectPhotoSeven2 from "app/assets/projects/seven/02.jpg";
import projectPhotoSeven3 from "app/assets/projects/seven/photo_3.png";
import projectPhotoSeven4 from "app/assets/projects/seven/photo_4.png";
import projectPhotoSeven5 from "app/assets/projects/seven/photo_5.png";
import projectPhotoSeven6 from "app/assets/projects/seven/photo_6.png";
import projectPhotoSeven7 from "app/assets/projects/seven/photo_7.png";

export const projects = [
  {
    id: "project-1",
    image: projectPhotoOne1,
    title: "Ремонт одноконтурных и двухконтурных котлов",
    photos: [
      {
        url: projectPhotoOne1,
      },
      {
        url: projectPhotoOne2,
      },
      {
        url: projectPhotoOne3,
      },
      {
        url: projectPhotoOne4,
      },
      {
        url: projectPhotoOne5,
      },
      {
        url: projectPhotoOne6,
      },
      {
        url: projectPhotoOne7,
      },
      {
        url: projectPhotoOne8,
      },
      {
        url: projectPhotoOne9,
      },
      {
        url: projectPhotoOne10,
      },
      {
        url: projectPhotoOne11,
      },
      {
        url: projectPhotoOne12,
      },
      {
        url: projectPhotoOne13,
      },
    ],
  },

  {
    id: "project-2",
    image: projectPhotoTwo1,
    title: "Профилактика газовых двухконтурных и одноконтурных котлов",
    photos: [
      {
        url: projectPhotoTwo1,
      },
      {
        url: projectPhotoTwo2,
      },
      {
        url: projectPhotoTwo3,
      },
      {
        url: projectPhotoTwo4,
      },
      {
        url: projectPhotoTwo5,
      },
      {
        url: projectPhotoTwo6,
      },
      {
        url: projectPhotoTwo7,
      },
      {
        url: projectPhotoTwo8,
      },
      {
        url: projectPhotoTwo9,
      },
      {
        url: projectPhotoTwo10,
      },
      {
        url: projectPhotoTwo11,
      },
      {
        url: projectPhotoTwo12,
      },
      {
        url: projectPhotoTwo13,
      },
      {
        url: projectPhotoTwo15,
      },
    ],
  },

  {
    id: "project-3",
    image: projectPhotoThree1,
    title: "Чистка систем отопления",
    photos: [
      {
        url: projectPhotoThree1,
      },
      {
        url: projectPhotoThree2,
      },
      {
        url: projectPhotoThree3,
      },
      {
        url: projectPhotoThree4,
      },
      {
        url: projectPhotoThree5,
      },
    ],
  },

  {
    id: "project-4",
    image: projectPhotoFour1,
    title: "Ремонт напольных котлов",
    photos: [
      {
        url: projectPhotoFour1,
      },
      {
        url: projectPhotoFour2,
      },
      {
        url: projectPhotoFour3,
      },
      {
        url: projectPhotoFour4,
      },
      {
        url: projectPhotoFour5,
      },
      {
        url: projectPhotoFour6,
      },
      {
        url: projectPhotoFour7,
      },
      {
        url: projectPhotoFour8,
      },
      {
        url: projectPhotoFour9,
      },
      {
        url: projectPhotoFour10,
      },
      {
        url: projectPhotoFour11,
      },
      {
        url: projectPhotoFour12,
      },
      {
        url: projectPhotoFour13,
      },
      {
        url: projectPhotoFour14,
      },
    ],
  },

  {
    id: "project-5",
    image: projectPhotoFive1,
    title: "Ремонт, чистка электрического и газового бойлера",
    photos: [
      {
        url: projectPhotoFive1,
      },
      {
        url: projectPhotoFive2,
      },
      {
        url: projectPhotoFive3,
      },
      {
        url: projectPhotoFive4,
      },
      {
        url: projectPhotoFive5,
      },
      {
        url: projectPhotoFive6,
      },
      {
        url: projectPhotoFive7,
      },
      {
        url: projectPhotoFive8,
      },
      {
        url: projectPhotoFive9,
      },
      {
        url: projectPhotoFive10,
      },
    ],
  },

  {
    id: "project-6",
    image: projectPhotoSix1,
    title: "Монтаж и переделка систем отопления",
    photos: [
      {
        url: projectPhotoSix1,
      },
      {
        url: projectPhotoSix2,
      },
      {
        url: projectPhotoSix3,
      },
      {
        url: projectPhotoSix4,
      },
      {
        url: projectPhotoSix5,
      },
      {
        url: projectPhotoSix6,
      },
      {
        url: projectPhotoSix7,
      },
      {
        url: projectPhotoSix8,
      },
      {
        url: projectPhotoSix9,
      },
      {
        url: projectPhotoSix10,
      },
      {
        url: projectPhotoSix11,
      },
    ],
  },

  {
    id: "project-7",
    image: projectPhotoSeven1,
    title: "Продажа запчастей для отопительного оборудования",
    photos: [
      {
        url: projectPhotoSeven1,
      },
      {
        url: projectPhotoSeven2,
      },
    ],
  },

  {
    id: "project-19",
    image: projectPhotoSix1,
    title: "Монтаж и демонтаж газовых двухконтурных и одноконтурных котлов.",
    photos: [],
  },

  {
    id: "project-15",
    image: projectPhotoFive1,
    title: "Чистка напольных котлов.",
    photos: [],
  },

  {
    id: "project-12",
    image: projectPhotoSeven1,
    title: "Обслуживание и запуск систем отопления.",
    photos: [],
  },
];
